/**
 * Capitalizes a string.
 * @param {string} str - String to capitalize
 * @returns {string} - string
 * @example
 * capitalize('storefront')
 * returns 'Storefront'
 * @category Utils
 */
export const capitalize = (str: string): string => str.charAt(0).toUpperCase() + str.slice(1)
