<template>
  <div v-for="(slotName, i) in Object.keys($slots)" :key="i">
    <div v-show="slotName === monetateDecisionId">
      <!-- eslint-disable-next-line -->
      <slot :name="slotName" :recommendedProducts />
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { MonetateRecommendedProduct } from '#types/monetate'
import { getEnvFromChannel } from '#core/utils/monetate'

const { ids } = defineProps<{
  /**
   * CMS Section with content components
   */
  ids: {
    sandbox: string
    production: string
  }
}>()
// You can force one variant to show up by setting a query url like ?variant=A or ?variant=Experiment
const { query } = useRoute()
const { monetateConfig } = useFeatureFlags()
const id = getEnvFromChannel(monetateConfig.monetateChannel) === 'PROD' ? ids.production : ids.sandbox

const monetateDecision = ref(window?.vfa?.get(id))
const monetateDecisionId = getVariant(capitalize(query.variant as string || '') || monetateDecision.value?.variant || 'Baseline')

const recommendedProducts = ref<MonetateRecommendedProduct[]>(
  monetateDecision.value?.items ? monetateDecision.value.items : []
)
</script>
