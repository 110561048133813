<template>
  <base-popover-content
    enter-from-class="op-0 -translate-y-8 will-change-transform"
    leave-to-class="op-0 -translate-y-8"
    enter-to-class="translate-y-0"
    leave-from-class="translate-y-0"
    enter-active-class="transform ease-out"
    leave-active-class="transform ease-in"
    class="z-above-header transition"
    :class="{ 'flex justify-end !left-0 w-full px-4': fixed }"
    :max-height-offset="maxHeightOffset"
    @keydown.esc="close"
  >
    <div class="relative overflow-y-auto rounded-lg bg-white p-4 shadow-sm">
      <base-button v-if="!closeOnLeave" class="absolute right-0 top-0 mr-4 mt-4" :aria-label="$t.close" @click="close">
        <vf-icon name="close" size="md" />
      </base-button>
      <slot />
    </div>
  </base-popover-content>
</template>

<script lang="ts" setup>
import { PopoverContextKey } from '#core/components/base/popover/context'

withDefaults(defineProps<{
  maxHeightOffset?: number
  fixed?: boolean
}>(), {
  fixed: true
})

const {
  closeOnLeave,
  forceClose: close
} = inject(PopoverContextKey)!

useRouter().beforeEach(close)
</script>
