<template>
  <span class="relative">
    <base-picture
      v-if="thumbnail"
      v-style:inset="inset"
      :src="thumbnail"
      class="absolute-0 overflow-hidden"
      :class="classInner"
      width="32"
      height="32"
      alt=""
      :lazy
    />
    <span v-else v-style:inset="inset" class="absolute-0" :class="classInner" :style="{ background: bg }" />
    <slot />
  </span>
</template>

<script lang="ts" setup>
import type { CSSClass, Responsive } from '#types/common'

const { color = [], thumbnail } = defineProps<{
  /**
   * Color value as an array of color codes or 'multicolor'
   */
  color?: string[]
  /**
   * Optional thumbnail that will take precedence over color
   */
  thumbnail?: string
  classInner?: CSSClass
  inset?: Responsive | string
  lazy?: boolean
}>()

const bg = (() => {
  if (!color?.length) return
  if (color[0] === 'multicolor') {
    // if props.color includes 'multicolor', bg is a rainbow conic gradient
    return 'conic-gradient(from 90deg, violet, indigo, blue, green, yellow, orange, red, violet)'
  }
  if (color.length === 1) {
    return color[0]
  }
  // if props.color includes more than one color, bg is a linear gradient with color stops for each color value
  else {
    return `linear-gradient(45deg,
    ${color.map((c, i) => `${c} ${(i * 100) / color.length}% ${((i + 1) * 100) / color.length}%`).join(', ')
    })`
  }
})()
</script>
