<template>
  <div
    v-style:mr="{ md: '13.5rem' }"
    class="fixed bottom-0 right-0 w-full shadow-sm md:w-80"
    @keydown.esc="$emit('close', category, action)"
  >
    <base-button
      v-if="!expanded"
      class="absolute inset-x-0 bottom-0 flex items-center gap-4 bg-inherit px-4 py-2 between"
      @click="togglePopup(true, 'popUp.onExpand')"
    >
      <slot name="title" />
      <span class="sr-only">
        ({{ $t.popupExpand }})
      </span>
      <vf-icon name="plus" size="md" />
    </base-button>
    <transition name="slide">
      <div v-if="expanded" class="slide-up bg-white">
        <div class="bg-$bg p-4 c-$c">
          <div class="mb-2 flex gap-4">
            <slot name="titleExpanded" />
            <base-button :aria-label="$t.popupMinimize" class="ml-a" @click="togglePopup(false, 'popUp.onCollapse')">
              <vf-icon name="minus" size="md" />
            </base-button>
            <base-button :aria-label="$t.popupClose" @click="$emit('close', category, action)">
              <vf-icon name="close" size="md" />
            </base-button>
          </div>
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts" setup>
import type { Category } from '#commerce/gtm/popUp'

const { action, category } = defineProps<{
  category: Category
  action?: string
}>()

defineEmits<{
  close: [category: Category, action?: string]
}>()

const { $gtm } = useNuxtApp()
const expanded = defineModel<boolean>()

const togglePopup = (expandedValue: boolean, eventType: 'popUp.onExpand' | 'popUp.onCollapse') => {
  expanded.value = expandedValue
  $gtm.push(eventType, category, action)
}
</script>
