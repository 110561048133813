<template>
  <base-button
    class="group max-w-full center px-2 py-1 i-flex duration"
    :class="brandClasses.container"
    data-test-id="vf-pill"
  >
    <span v-if="$slots.before" style="margin: 0 0.5rem 0 0">
      <slot name="before" />
    </span>
    <span class="truncate text-xs c-grey-20" data-test-id="vf-pill-text">
      <slot />
    </span>
    <span v-if="$slots.after" class="text-xs">
      <slot name="after" />
    </span>
    <span
      v-if="removable"
      :class="{ relative: removeIconSecondary }"
      style="margin: 0 0 0 0.5rem"
      data-test-id="vf-pill-close"
    >
      <vf-icon :name="removeIcon" :class="{ 'absolute group-hocus:op-0': removeIconSecondary }" />
      <vf-icon v-if="removeIconSecondary" :name="removeIconSecondary" class="op-0 group-hocus:op-100" />
    </span>
  </base-button>
</template>

<script lang="ts" setup>
defineProps<{
  /**
   * Defines whether close icon is shown or not
   */
  removable?: boolean
}>()

const { brandClasses, removeIcon, removeIconSecondary } = useAppConfig().components.vf.pill
</script>
