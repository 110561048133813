<template>
  <i
    v-style="{ w, h }"
    aria-hidden="true"
    class="block shrink-0 bg-current"
    :class="{ 'transform transition-transform': dir }"
    :style="{
      ...(dir && { transform: `rotate(${dirs[dir]}deg)` }),
      'mask': `url('${url}') ${pos} / contain no-repeat`,
      '-webkit-mask': `url('${url}') ${pos} / contain no-repeat`,
    }"
  />
</template>

<script lang="ts" setup>
import type { Responsive } from '#types/common'
import type { IconDir, IconName } from '#types/icon'
import type { IconSizes } from '#types/sizes'
import sprite from '#root/assets/icons.svg'

const props = withDefaults(
  defineProps<{
    name?: IconName
    size?: IconSizes | Partial<Responsive<IconSizes>>
    dir?: IconDir
    src?: string
    pos?: string
    height?: string | number | Partial<Responsive>
    width?: string | number | Partial<Responsive>
  }>(),
  {
    size: () => ({ sm: 'sm' }),
    pos: 'left'
  }
)

const config = useAppConfig().components.vf.icon

if (!props.name && !props.src) console.warn('Icon: missing source!')

const dirs = { right: 0, left: 180, up: -90, down: 90 }

const s = computed(() =>
  mapObject(isObject(props.size) ? props.size : { sm: props.size }, (br) => `${config.size[br!] / 16}rem`)
)
const w = computed(() =>
  props.width
    ? mapObject(isObject(props.width) ? props.width : { sm: props.width }, (val) => `${+val! / 16}rem`)
    : s.value
)
const h = computed(() =>
  props.height
    ? mapObject(isObject(props.height) ? props.height : { sm: props.height }, (val) => `${+val! / 16}rem`)
    : s.value
)

const url = computed(() => props.src || `${sprite}#${props.name}`)
</script>
