import { withQuery } from 'ufo'

export default () => {
  const languageCode = useLanguageCode()
  const countryCode = useCountryCode()
  const { accountId, accountSignature } = useAppConfig().components.syte || {}
  const src = withQuery('https://cdn.syteapi.com/assets/imajs/imajs.js', {
    a: accountId,
    sig: accountSignature,
    languageCode: `${languageCode.toLowerCase()}_${countryCode}`
  })

  const ready = useState(() => false)

  onMounted(() => {
    useEventListener(window, 'syteapp_is_ready', () => ready.value = true)
  })

  const { $script } = useScript({ src, defer: true }, {
    mode: 'client',
    trigger: 'manual',
  })

  return {
    init: $script.load,
    ready
  }
}
