import { randomUUID as uuid } from 'uncrypto'
import type { LocationQuery } from 'vue-router'
import type {
  MonetateAction,
  MonetateCartLine,
  MonetateCustomStates,
  MonetateCustomVariable,
  MonetateEvent,
  MonetateEventType,
  MonetateExtractedStates,
  MonetateMappedExperience,
  MonetatePageType,
  MonetatePurchaseLine
} from '#types/monetate'

const patterns = [
  { pattern: /\/p\//, value: 'PDP' },
  { pattern: /\/c\//, value: 'PLP' },
  { pattern: /\/cart/, value: 'Cart' },
  { pattern: /\/account/, value: 'Account' },
  { pattern: /\/search/, value: 'Search' },
  { pattern: /[a-z]{2}-[a-z]{2}\/*$/, value: 'Home' },
  { pattern: /\/order-confirmation/, value: 'Order Confirmation' },
  { pattern: /\/checkout/, value: 'Checkout' }
].map(({ pattern, value }) => ({
  pattern: new RegExp(pattern),
  value
}))

/**
 * Extracts the page type from a given path using a set of predefined patterns.
 * @param path The path from which to extract the page type.
 * @category Utils
 * @returns The extracted page type, or 'Other' if no match is found.
 */
export const extractPageType = (path: string) => {
  for (const { pattern, value } of patterns) {
    if (pattern.test(path))
      return <MonetatePageType>value
  }

  return 'Other'
}

/**
 * Extracts categories from a given path and optionally adds additional categories.
 * @param path The path from which to extract the categories.
 * @param additionalCategories Additional categories to include (optional).
 * @returns An array of extracted categories.
 * @category Utils
 */
export const extractCategories = (path: string, additionalCategories?: string[]) => {
  const categories: string[] = []

  if (path.match(/\/c\//))
    categories.push(...path.split('/').slice(3).map((str) => str.replace(/-\d+$/, '')))

  if (additionalCategories?.length)
    categories.push(...additionalCategories)

  return categories
}

/**
 * Maps an item to either a MonetateCartLine or MonetatePurchaseLine object.
 * @param item The item to map.
 * @returns A MonetateCartLine or MonetatePurchaseLine object.
 * @category Utils
 */
export const mapItemLine = (item): MonetateCartLine | MonetatePurchaseLine => ({
  currency: item.price.currency,
  pid: item.masterId,
  quantity: item.qty,
  sku: item.slug,
  value: item.price.rowTotal.toFixed(2)
})

/**
 * Extracts additional Monetate events based on the provided event content, event type, and Monetate environment.
 * @param eventContent The content of the event.
 * @param eventType The type of the Monetate event.
 * @param monetateEnv The Monetate environment.
 * @returns An array of additional Monetate events.
 * @category Utils
 */
export const extractExtraEvents = (
  eventContent,
  eventType: MonetateEventType,
  monetateEnv
) => {
  const additionalEvents: MonetateEvent[] = []

  if (eventType === 'monetate:context:Purchase' && eventContent?.items) {
    additionalEvents.push({
      eventType,
      account: eventContent.consumerId,
      domain: window.location.host,
      instance: monetateEnv,
      purchaseId: eventContent.orderNumber,
      purchaseLines: eventContent.items.map(mapItemLine)
    })
  }

  if (eventType === 'monetate:context:ProductThumbnailView' && eventContent?.length) {
    const productIds = eventContent.map((product) => product.id)
    additionalEvents.push({
      eventType,
      products: productIds
    })
  }

  if (eventType === 'monetate:context:ProductDetailView' && eventContent?.id) {
    additionalEvents.push({
      eventType,
      products: [
        {
          productId: eventContent.id
        }
      ]
    })
  }

  return additionalEvents
}

/**
 * Extracts custom states from Monetate custom states object.
 * @param customStates The Monetate custom states object.
 * @returns An object containing extracted custom variables and state names.
 * @category Utils
 */
const extractCustomStates = (customStates: MonetateCustomStates): MonetateExtractedStates => {
  const extractedCustomStates: MonetateExtractedStates = {
    customVars: [],
    customStatesNames: []
  }

  for (const [key, value] of Object.entries(customStates)) {
    if (value !== undefined) {
      const stringedValue = value?.toString() || ''
      extractedCustomStates.customVars.push({
        variable: key,
        value: stringedValue
      })
    }

    extractedCustomStates.customStatesNames.push(`${value ? '' : 'not-'}${key}`)
  }

  return extractedCustomStates
}

/**
 * Extracts base Monetate events based on provided parameters such as page details, screen size, language, user agent, etc.
 * @param to An object containing page details like fullPath, path, and query.
 * @param to.fullPath The fullPath attribute on the to object.
 * @param to.path The path attribute on the 'to' object.
 * @param to.query The query attribute on the 'to' object.
 * @param options The height, width, coordinates, customStates, interests, and cart options
 * @param options.height The height of the screen.
 * @param options.width The width of the screen.
 * @param options.coordinates The coordinates of the user.
 * @param options.customStates The custom states object.
 * @param options.interests The interests of the user.
 * @param options.cart The cart details.
 * @param options.stealth If the user is in a stealth group.
 * @returns An array of base Monetate events.
 * @category Utils
 */
export const extractBaseEvents = (
  to: {
    fullPath: string
    path: string
    query: LocationQuery
  },
  { height, width, coordinates, customStates, interests, cart, stealth }
) => {
  const pageType = extractPageType(to.path) || ''
  const customVars: MonetateCustomVariable[] = []
  const customStatesNames: string[] = []

  if (customStates) {
    const extractedCustomState = extractCustomStates(customStates)
    customVars.push(...extractedCustomState.customVars)
    customStatesNames.push(...extractedCustomState.customStatesNames)
  }

  const baseEvents: MonetateEvent[] = [
    {
      eventType: 'monetate:context:PageView',
      url: window.location.href,
      pageType,
      breadcrumbs: extractCategories(to.fullPath),
      categories: extractCategories(to.fullPath, customStatesNames)
    },
    {
      requestId: uuid(),
      eventType: 'monetate:decision:DecisionRequest',
      includeReporting: true
    },
    {
      eventType: 'monetate:context:ScreenSize',
      height,
      width
    },
    {
      eventType: 'monetate:context:Language',
      language: to.path.split('/')[1]
    },
    {
      eventType: 'monetate:context:UserAgent',
      userAgent: window?.navigator?.userAgent
    }
  ]

  if (window.location.host.includes('preprod') && stealth !== 'trackingGroup') {
    baseEvents.push({
      eventType: 'monetate:context:IpAddress',
      ipAddress: '123.123.123.123'
    })
  }

  if (pageType === 'PDP') {
    baseEvents.push({
      eventType: 'monetate:context:ProductDetailView',
      products: [
        {
          productId: `${to.path.match(/-([A-Z]{2}[A-Z\d]{6,})$/)?.[1]}${to?.query.color || ''}`
        }
      ]
    })
  }

  if (document?.referrer) {
    baseEvents.push({
      eventType: 'monetate:context:Referrer',
      referrer: document.referrer
    })
  }

  if (coordinates) {
    const [latitude, longitude] = coordinates.split(',')
    baseEvents.push({
      eventType: 'monetate:context:Coordinates',
      latitude,
      longitude
    })
  }

  if (pageType === 'Search') {
    baseEvents.push({
      eventType: 'monetate:context:SearchView',
      searchTerm: to.query?.q as string,
      searchTermType: 'text',
      searchType: 'site'
    })
  }

  if (cart?.items?.length) {
    baseEvents.push({
      eventType: 'monetate:context:Cart',
      cartLines: cart.items
    })
  }

  if (interests?.length) {
    customVars.push(
      {
        variable: 'userInterest',
        value: interests.filter(Boolean)
      }
    )
  }

  if (cart?.cartItemTotal) {
    customVars.push({
      variable: 'total-cart',
      value: cart?.cartItemTotal
    })
  }

  if (cart?.discount) {
    customVars.push({
      variable: 'sub-total',
      value: cart?.totals?.itemTotal - cart?.totals?.totalDiscount
    })
  }

  if (customVars?.length) {
    baseEvents.push({
      eventType: 'monetate:context:CustomVariables',
      customVariables: customVars
    })
  }

  return baseEvents
}

/**
 * Gets the variant based on the provided label.
 * @param label The label used to determine the variant.
 * @returns The variant corresponding to the label.
 * @category Utils
 */
export const getVariant = (label: string): string => {
  // This is meant to account for the 2 edge cases of Baseline and Experiment and to evaluate B-Holdout as B
  switch (true) {
    case (label.includes('Experiment')):
      return 'A'
    case (label.includes('Baseline')):
      return 'Baseline'
    case (label[1] === '-'):
      return label[0]
    default:
      return label
  }
}

/**
 * Reduces an array of Monetate actions into a map with experience IDs as keys and corresponding variant and items as values.
 * @param actions An array of Monetate actions to reduce.
 * @returns A map with experience IDs as keys and variant and items as values.
 * @category Utils
 */
export const reduceActions = (actions: MonetateAction[]) => {
  return actions.reduce<Map<string, MonetateMappedExperience>>((vfaMap, action: MonetateAction) => {
    const {
      experience_id,
      experience_name,
      experience_label,
      variant_label
    } = action.impressionReporting?.[0] ?? {}

    // Experiment is converted to A, Control is preserved while in the other cases we use the first letter representing the experiment to avoid cases where variant label contain holdout
    const variant = getVariant(variant_label)
    const experienceId = experience_id.toString()
    const experienceName = experience_name.toString()
    const experienceLabel = experience_label.toString()

    vfaMap.set(experienceId, {
      // for context behind the letter "A" visit https://digital.vfc.com/stash/projects/VEF/repos/canvas-storefront/pull-requests/2641/overview?commentId=193594
      variant,
      id: experienceId,
      name: experienceName,
      label: experienceLabel,
      actionId: action.actionId,
      component: action.component,
      items: action.items
    })
    return vfaMap
  }, new Map())
}

/**
 * Gets the env based on the monetate channel.
 * @param channel The monetate channel.
 * @returns PROD or SANDBOX.
 * @category Utils
 */
export const getEnvFromChannel = (channel) => {
  const parts = channel.split('/')
  return parts[1] === 'p' ? 'PROD' : 'SANDBOX'
}
