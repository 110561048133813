<template>
  <base-popover
    v-slot="props"
    :placement="placement"
    :flip="flip"
    :delay="delay"
    :offset="{ mainAxis: 10, alignmentAxis: -20 }"
    :arrow-shift="-6"
    :teleport="teleport"
    class="flex"
    data-test-id="vf-tooltip"
  >
    <base-button
      :aria-labelledby="id"
      :class="classTrigger"
      @focus="props.open"
      @blur="props.close"
      @mouseenter="props.open"
      @mouseleave="props.close"
      @keydown.esc="props.close"
      @click="$emit('click')"
    >
      <!-- @slot Slot for tooltip trigger -->
      <slot name="trigger" v-bind="props" />
    </base-button>
    <base-popover-content
      :id
      enter-from-class="op-0 scale-95"
      enter-active-class="transform ease-out"
      enter-to-class="translate-y-0 scale-100"
      leave-from-class="translate-y-0 scale-100"
      leave-active-class="transform ease-in"
      leave-to-class="op-0 scale-95"
      role="tooltip"
      class="z-overlay b b-grey-70 bg-white px-4 py-6 text-sm shadow-xs transition"
      style="max-width: 15rem"
      data-test-id="vf-tooltip-content"
    >
      <!-- @slot Slot for toggletip content -->
      <slot v-bind="props" />
      <base-popover-arrow
        class="absolute transform b-grey-70 bg-white"
        :class="props.side === 'top' ? 'b-b b-r' : 'b-t b-l'"
        style="width: 0.625rem; height: 0.625rem; --un-rotate: 45deg;"
      />
    </base-popover-content>
  </base-popover>
</template>

<script lang="ts" setup>
import type { CSSClass } from '#types/common'

withDefaults(defineProps<{
  /**
   * The position of the Tooltip.
   * By default `bottom`.
   */
  placement?: 'top' | 'top-start' | 'top-end' | 'bottom' | 'bottom-start' | 'bottom-end'
  /**
   * If tooltip should change the placement of the floating element to the opposite
   * on scroll/resize
   */
  flip?: boolean
  /**
   * Show/Hide delay, or object in ms.
   */
  delay?: number | { show?: number, hide?: number }
  /**
   * Where the Popover should be mounted to. By default it will be mounted to the body.
   */
  teleport?: boolean | string | HTMLElement
  /**
   * Classes to be applied to trigger container.
   */
  classTrigger?: CSSClass
}>(), {
  flip: true,
  teleport: true
})

defineEmits<{
  (e: 'click'): void
}>()

const id = useId()
</script>
