<template>
  <component
    :is="to ? BaseLink : BaseButton"
    v-style="container"
    :to
    class="group flex center outline-none"
    :aria-current="active"
    data-test-id="vf-color-picker"
  >
    <vf-loader v-if="loading" class="absolute z-overlay h-20" />
    <vf-color-swatch
      v-bind="{ color, unavailable, lazy }"
      :thumbnail="getImageTransformations(thumbnail, config.thumbnail[variant])"
      class="full duration group-focus-visible:outline-auto"
      :class="[
        active ? brandClasses[variant].swatchActive : brandClasses[variant].swatch,
        { 'rounded-full': variant === 'round' },
      ]"
      :class-inner="brandClasses[variant].swatchInner"
      :inset="unavailable ? swatchUnavailable?.inset : swatch?.inset"
    >
      <span v-if="name" class="sr-only">{{ name }}</span>
      <span
        v-if="unavailable"
        v-style="cross"
        class="absolute-0"
        :class="[brandClasses[variant].cross, { 'rounded-full': variant === 'round' }]"
      />
    </vf-color-swatch>
  </component>
</template>

<script lang="ts" setup>
import { BaseButton, BaseLink } from '#components'
import type { Responsive } from '#types/common'
import type { ColorPickerStyles } from '#types/config/components/vf/colorPicker'
import type { ColorSwatchShape } from '#types/product'
import type { SizesSubUnion } from '#types/sizes'

const { size, variant } = withDefaults(
  defineProps<{
    /**
     * ColorPicker will be a link if this prop is provided
     */
    to?: string
    /**
     * Color value as an array of color codes or 'multicolor'
     */
    color?: string[]
    /**
     * Source for color thumbnail image
     */
    thumbnail?: string
    /**
     * Defines the size of the color picker
     */
    size?: SizesSubUnion<'sm' | 'md' | 'lg'> | Responsive<SizesSubUnion<'sm' | 'md' | 'lg'>>
    /**
     * Defines the shape of the color picker
     */
    variant?: ColorSwatchShape
    /**
     * Unavailable means the variant is out of stock, it will appear disabled but remain selectable
     */
    unavailable?: boolean
    /**
     * Whether color is selected
     */
    active?: boolean
    /**
     * Whether to lazy load the thumbnail image
     */
    lazy?: boolean
    /**
     * Whether to lazy load the thumbnail image
     */
    loading?: boolean
    /**
     * Required color name for screen reader
     */
    name?: string
  }>(),
  {
    size: 'md',
    variant: 'round',
    lazy: true
  }
)

const { brandClasses, brandStyles, config } = useAppConfig().components.vf.colorPicker

const {
  container,
  cross,
  swatch,
  swatchUnavailable
} = Object.entries(brandStyles[variant]).reduce((acc: ColorPickerStyles, [key, value]) => {
  acc[key] = {}

  for (const styleProp in value)
    acc[key][styleProp] = replaceValues(isObject(size) ? size : { sm: size }, value[styleProp])

  return acc
}, {})
</script>
