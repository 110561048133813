<template>
  <label
    class="relative h-12 w-full flex b rounded-sm duration"
    :class="[
      disabled ? 'bg-grey-90 b-grey-50 c-grey-30' : selectBrandClasses.borderColor,
      {
        'bg-white cursor-pointer focus-within:b-grey-10 hover:b-grey-10': !disabled,
        'b-red-60': variant,
        'b-red-30': invalid,
      }]"
    data-test-id="vf-select"
  >
    <span
      class="pointer-events-none absolute-0 mr-2 mt-1"
      :class="[labelBrandClasses.selectText, { 'c-red-30': invalid, 'c-grey-20': !invalid && !disabled }]"
      data-test-id="vf-select-label"
    >
      <!-- @slot Slot is meant for `label` only -->
      <slot />
      <span v-if="required" aria-hidden="true"> *</span>
    </span>
    <base-select
      v-bind="{ options, placeholder, disabled, required }"
      v-model="modelValue"
      class="full pb-1 pl-3 pr-10 pt-5"
      :class="[
        selectBrandClasses.text,
        { 'c-grey-30': disabled },
        modelValue ? selectBrandClasses.textSelected : 'c-grey-20',
      ]"
      @update:model-value="$emit('change', $event)"
    />
    <vf-icon
      name="chevron"
      :size="iconSize"
      dir="down"
      class="pointer-events-none absolute inset-y-0 right-0 my-a"
      :class="[selectBrandClasses.icon, disabled ? 'c-grey-50' : 'c-grey-10']"
    />
  </label>
</template>

<script lang="ts" setup>
import type { SelectOption } from '#types/components/base/select'

defineProps<{
  /**
   * Defines list of options
   */
  options: (string | SelectOption)[] | (() => Promise<SelectOption[]>)
  /**
   * Defines placeholder when no selection
   */
  placeholder?: string
  /**
   * Defines disabled state
   */
  disabled?: boolean
  /**
   * Defines invalid state
   */
  invalid?: boolean
  /**
   * Defines if the select is required for accessibility purposes
   */
  required?: boolean
  /**
   * Defines variant
   */
  variant?: 'inverse'
}>()

defineEmits<{ change: [value: string | number | object] }>()

const {
  label: { brandClasses: labelBrandClasses },
  select: { brandClasses: selectBrandClasses, iconSize }
} = useAppConfig().components.vf.form
const modelValue = defineModel<string | number | object>()
</script>
