<template>
  <nav
    class="flex items-center gap-x-2 c-grey-30"
    aria-label="Breadcrumb"
    data-test-id="vf-breadcrumbs"
  >
    <slot name="firstStep">
      <base-link
        :to="items.length > 1 ? items[items.length - 2].url : '/'"
        class="-ml-2 lg:hidden c-grey-10"
        :aria-label="items.length > 1 ? items[items.length - 2].label : $t.home"
      >
        <vf-icon name="chevron" :size="iconSize" dir="left" />
      </base-link>
      <base-link
        to="/"
        class="duration <lg:hidden hover:no-underline hover:c-grey-10"
      >
        {{ $t.home }}
      </base-link>
      <span class="<lg:hidden ">
        <slot name="separator">/</slot>
      </span>
    </slot>
    <template v-for="({ label, url }, i) of items" :key="i">
      <base-link
        :to="url"
        class="max-w-1/2 shrink-0 truncate duration hover:no-underline hover:c-grey-10"
        :class="{ '<lg:hidden': i < items.length - 1 }"
      >
        {{ label }}
      </base-link>
      <span
        v-if="lastStep || i < items.length - 1"
        :key="`${i}-separator`"
                :class="{ '<lg:hidden': i < items.length - 1 }"
      >
        <slot name="separator">/</slot>
      </span>
    </template>
    <template v-if="lastStep">
      <span class="truncate c-grey-10 ">
        {{ lastStep.label }}
      </span>
    </template>
  </nav>
</template>

<script lang="ts" setup>
import type { Breadcrumb } from '#types/components/vf/breadcrumbs'

defineProps<{
  /**
   * List of breadcrumb items
   */
  items: Breadcrumb[]
  /**
   * Data for special styled last breadcrumb step
   */
  lastStep?: Breadcrumb
}>()

const { iconSize } = useAppConfig().components.vf.breadcrumbs
</script>
