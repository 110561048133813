/**
 * Check if color is light or dark
 * @param colorCode hex code of color
 * @category Utils
 * @returns true if color is light, false if color is dark
 */

export function hasHighBrightness(hex = '') {
  // Perceived brightness of red green and blue
  const brightnesses = [0.299, 0.587, 0.114]
  const [r, g, b] = hex.padStart(6, hex[0]) // ensure hex code is not using 3 digit shorthand
    .match(/.{1,2}/g)!.map((code, i) => Number.parseInt(code, 16) * brightnesses[i])

  // luminance as is computed as value between 0 and 1, values above 0.5 indicates low contrast
  return (r + g + b) / 255 > 0.5
}
