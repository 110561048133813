/**
 * Returns a new object or string depending on the provided input with the same keys as the input object.
 * However, with value(s) replaced according to the replacement object
 * if replacement value is not provided, the original value is returned
 * @category Utils
 * @param input - The input object or string to be processed.
 * @param replacements - The replacement object that maps values to their replacements.
 * @returns The processed object or string with values replaced according to the replacement object.
 * @example
 * replaceValues(
 * { sm: 'tertiary-inverse', md: 'tertiary' },
 * { 'tertiary-inverse': 'secondary-inverse', 'tertiary': 'secondary' }
 * )
 * returns {sm: 'secondary-inverse', md: 'secondary'}
 * replaceValues('tertiary', { 'tertiary': 'secondary'})
 * returns 'secondary'
 */
export const replaceValues
  = <T extends object, S extends object>(input: string | T, replacements: S): string | T =>
    typeof input === 'string'
      ? (replacements[input] || input)
      : Object.fromEntries(Object.entries(input)?.map(([key, val]) => [key, replacements[val] || val]))
