<template>
  <span class="h-8 overflow-hidden b b-grey-40 rounded-sm i-flex " data-test-id="vf-stepper">
    <base-button
      class="w-8 flex center"
      :class="{ 'bg-grey-90 c-grey-50': disabled || model <= min }"
      :aria-label="`${$t.decrease} ${label || $t.quantity}`"
      :disabled="disabled || model <= min"
      @click="model -= step"
    >
      <vf-icon name="minus" :size="iconSize" />
    </base-button>
    <input
      :value="model"
      type="number"
      :min
      :max
      :aria-label="label || $t.quantity"
      :disabled
      class="pointer-events-none w-10 b-x b-grey-40 text-center text-md "
    >
    <base-button
      class="w-8 flex center"
      :class="{ 'bg-grey-90 c-grey-50': disabled || model >= max }"
      :aria-label="`${$t.increase} ${label || $t.quantity}`"
      :disabled="disabled || model >= max"
      @click="model += step"
    >
      <vf-icon name="plus" :size="iconSize" />
    </base-button>
  </span>
</template>

<script setup lang="ts">
withDefaults(defineProps<{
  /**
   * Minimum value inclusive
   */
  min?: number
  /**
   * Maximum value inclusive
   */
  max?: number
  /**
   * Step increase/decrease by pressing the buttons
   */
  step?: number
  /**
   * Defines disabled state
   */
  disabled?: boolean
  /**
   * Accessible label for stepper
   */
  label?: string
}>(), { min: 1, max: 100, step: 1 })

const model = defineModel<number>({ default: 1 })

const { iconSize } = useAppConfig().components.vf.stepper
</script>
