<template>
  <div class="fixed-0 z-overlay mx-a flex center" :class="{ 'md:container md:py-4': size !== 'full' }">
    <base-dialog-root default-open @update:open="$emit('resolve')">
      <base-dialog-overlay
        v-show="size !== 'full'"
        class="vf-vycjfu"
        style="background: #000000bf"
        data-test-id="vf-modal-backdrop"
      />
      <vf-dialog-content
        class="relative max-h-full w-full overflow-y-auto bg-white px-4 py-10 shadow-xl transition [.nested-enter-from_&,.nested-leave-to>&]:scale-95 [.nested-enter-from_&,.nested-leave-to>&]:op-0 md:px-10 [.nested-enter-active_&]:ease-out [.nested-leave-active_&]:ease-in"
        :class="[sizes[size], classContent]"
        :style="styleContent"
        :disable-outside-interaction="disableOutsideInteraction"
      >
        <slot />
        <base-dialog-close
          v-if="!hideCloseButton"
          :aria-label="$t.close"
          class="absolute right-0 top-0 mr-4 mt-4"
          data-test-id="vf-modal-close"
        >
          <slot name="close">
            <vf-icon name="close" size="md" />
          </slot>
        </base-dialog-close>
      </vf-dialog-content>
    </base-dialog-root>
  </div>
</template>

<script lang="ts" setup>
import type { CSSClass } from '#types/common'
import type { DialogSize } from '#types/composables/useDialog'

export type Props = {
  /**
   * Size of the modal.
   */
  size?: DialogSize
  /**
   * Classes to be applied to content container.
   */
  classContent?: CSSClass
  /**
   * Styles to apply to content container.
   */
  styleContent?: string
  /**
   * Whether "close" button is shown.
   */
  hideCloseButton?: boolean
  /**
   * Whether to disable the outside interaction
   */
  disableOutsideInteraction?: boolean
}

withDefaults(
  defineProps<Props>(),
  {
    size: 'xs'
  }
)

defineEmits<{
  resolve: []
}>()

defineSlots<{
  default?: () => any
  close?: () => any
}>()

const sizes = {
  xs: 'md:w-2/3 lg:w-1/2',
  sm: 'md:w-2/3',
  md: 'md:w-5/6',
  full: 'h-full'
}
</script>
